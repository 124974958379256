import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { Allroute } from './route/route';

function App() {

  return (
    <div> 
        <Allroute />
    </div>
  );
}

export default App;
