import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
const Layout= lazy(() => import('../layouts/layouts')); // New Layout component
// Lazy load the components
const Login = lazy(() => import('../authentication/login/login'));
const Sinup = lazy(() => import('../authentication/sinup/sinup'));
const Forgot = lazy(() => import('../authentication/forgot/forgot'));
const Home = lazy(() => import('../pages/dashboard/home'));
const PersonalLoan = lazy(() => import('../pages/personalloan/personalloan'));
const Presondetails  = lazy(() => import('../pages/personalloan/Presondetails/presondetails'));
const  Personform= lazy(() => import('../pages/personalloan/personform/personform'));
const CreditCard = lazy(() => import('../pages/creditcards/creditcards')); // Added CreditCard
const About = lazy(() => import('../requiredpages/about/about'));
const Disclaimer = lazy(() => import('../requiredpages/disclaimer/disclaimer'));
const Privacypolicy = lazy(() => import('../requiredpages/privacypolicy/privacypolicy'));
const Terms = lazy(() => import('../requiredpages/team/team'));
const Carddetails =lazy(()=> import("../pages/creditcards/creditcards") )
// Simulating authentication status (Replace this with your actual logic)
const isAuthenticated = () => {
  return localStorage.getItem('authToken') !== null;
};

export function Allroute() {
  const basePath = process.env.REACT_APP_BASE_PATH || '';

  return (
    <Router basename="/">
      <Suspense fallback={""}>
        <Routes>
          {/* Layout wraps all routes that share common elements like Header and Footer */}
          <Route path={`${basePath}/`} element={<Layout />}>
            <Route index element={isAuthenticated() ? <Home /> : <Login />} />
            <Route path="home" element={<Home />} />
            <Route path="personalloan" element={<PersonalLoan />} />
            <Route path="/personalloan/:bank/:id" element={<Presondetails />} />
            <Route path="personform" element={<Personform />} />
            <Route path="creditcard" element={<CreditCard />} />
            <Route path="/creditcard/:bank/:id" element={<Carddetails />} />
            <Route path="aboutus" element={<About />} />
            <Route path="disclaimer" element={<Disclaimer />} />
            <Route path="privacypolicy" element={<Privacypolicy />} />
            <Route path="terms" element={<Terms />} />
          </Route>

          {/* Routes outside the main layout */}
          <Route path={`${basePath}/login`} element={<Login />} />
          <Route path={`${basePath}/sinup`} element={<Sinup />} />
          <Route path={`${basePath}/forgot`} element={<Forgot />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
